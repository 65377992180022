<template>
  <b-container fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col
        cols="12"
        md="6"
        class="right-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <!-- Login -->
          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group label="Nome:" v-if="!isLogin">
              <b-form-input
                placeholder="Nome completo"
                v-model="name"
                type="text"
                name="name"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('name')">
                Por favor, insira seu nome completo.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="E-mail:">
              <b-form-input
                placeholder="exemplo@exemplo.com"
                v-model="email"
                type="text"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('email')">
                Por favor, insira seu e-mail.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Senha:">
              <b-form-input
                placeholder="********"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required|min:8'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('password')">
                Por favor, insira sua senha.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-invalid-feedback :state="success">
              E-mail ou senha estão incorretos.
            </b-form-invalid-feedback>

            <b-row class="mt-5">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
                <b-button
                  ref="button"
                  :disabled="loading"
                  type="submit"
                  class="btn-login"
                  >{{ isLogin ? "Login" : "Criar conta" }}</b-button
                >
              </b-overlay>
            </b-row>

            <b-row class="justify-content-center mt-3" v-if="isLogin">
              Ainda não tem conta?
              <a
                href="javascript:void(0);"
                @click="isLogin = false"
                class="ml-2 text-green"
                >Cadastre-se!</a
              >
            </b-row>
          </b-form>
        </div>
      </b-col>
      <b-col cols="12" md="6" class="left-side"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SellerRegisterService from "@/services/resources/SellerRegisterService";
import AffiliateService from "@/services/resources/AffiliateService";
const service = SellerRegisterService.build();
const serviceAffiliate = AffiliateService.build();

export default {
  name: "Login",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      hash: null,
      success: true,
      loading: false,
      isLogin: true,
      submit: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.isLogin) {
        this.login();
      } else {
        this.register();
      }
    },
    login() {
      this.submit = true;
      this.success = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          var that = this;

          let data = {
            username: this.email,
            password: this.password,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then((response) => {
              this.acceptInvite();
            })
            .catch(function (err) {
              that.success = false;
              that.loading = false;
            });
        }
      });
    },
    register() {
      this.submit = true;
      this.success = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          let data = {
            name: this.name,
            email: this.email,
            password: this.password,
          };

          service
            .create(data)
            .then((response) => {
              // Login
              let data = {
                username: this.email,
                password: this.password,
              };

              this.$store
                .dispatch("loginRequest", data)
                .then((response) => {
                  this.acceptInvite();
                })
                .catch(function (err) {
                  that.success = false;
                  that.loading = false;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    acceptInvite() {
      let data = {
        hash: this.hash,
        url: "accept-invite",
      };

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          this.$bvToast.toast("Este convite é inválido ou já foi utilizado!", {
            title: "Convite",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$store.dispatch("logoutRequest").catch(function (err) {});
        });
    },
  },
  mounted() {
    this.hash = this.$route.params.token;
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}

.bg-login {
  background: $base-color;
}

.btn-login {
  border: 2px $base-color solid;
  color: $base-color;
  font-weight: bold;
  border-radius: 30px;
  background: #fff;
  width: 100%;

  &:hover {
    background: $base-color;
    color: #fff;
  }
}

.left-side {
  background-image: url("~@/assets/img/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px;
  height: 100%;

  .inner-wrapper {
    margin-top: 100px;

    .logo {
      display: flex;
      justify-content: center;
      padding: 5px;
      img {
        width: 200px;
        height: auto;
      }
    }
  }
}
</style>